@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden; /* Hide scroll bars */
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column; /* Stack the child elements vertically */
  justify-content: flex-start; /* Align the child elements at the start */
  overflow: auto;
}

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.flexWrapCenter {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  align-items: stretch;
  width: 100%;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  text-align: center;
  transition: background-color 0.3s; /* Add transition for smooth color change */
  word-wrap: break-word;
  overflow-wrap: anywhere;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;
}

@media (max-width: 1224px) {
  .flexWrapCenter {
    grid-gap: 5px;
  }
  .circle {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .flexWrapCenter {
    grid-gap: 5px;
  }
  .circle {
    font-size: 14px;
  }
}

.circle.assets {
  background-color: #AB9DE6; /* Color for circles in Assets page */
}

.circle.workload {
  background-color: #7AC7CF; /* Color for circles in Workload page */
}

.circle.selected {
  filter: brightness(70%);
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.common-header {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

h1 {
  text-align: center;
}

h2 {
  letter-spacing: 2px;
  text-align: left;
}

.thankYouMessage {
  font-size: 2em;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #AB9DE6;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.centeredText {
  margin: 10px 0;
  text-align: center;
}

.centeredButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  padding: 10px;
}

.centeredButtonContainer.singleButton {
  justify-content: flex-end;
}

.button {
  align-items: center;
  display: flex;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #007BFF;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 2;
}

.button:hover {
  background-color: #0056b3;
}

.button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.button-container {
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.link-button {
  background: none!important;
  border: none;
  padding: 0!important;
  font-family: 'Roboto', sans-serif;
  color: #069;
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
}

.delete-button {
  align-items: center;
  display: flex;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #e60000; /* Darker red */
  color: white;
}

.modal-content {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}

.arrow {
  position: fixed;
  right: 5px;
  transform: translateX(-50%);
  opacity: 0.5;
  cursor: pointer;
  z-index: 1;
}

.arrow.up {
  top: 10px;
}

.arrow.down {
  bottom: 10px;
}

.error-message {
  color: red;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: red;
  cursor: pointer;
  float: right;
  margin-left: 10px;
}